.page-id-650 .avia-button-fullwidth:hover {
  background: #d6885b !important;
}

.services {
  .entry-content-wrapper {
    @media only screen and (max-width: 989px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
}
