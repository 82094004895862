#top #main .sidebar {
	border: none !important
}


#top .av-large-testimonial-slider .avia-testimonial-content,
#top .av-large-testimonial-slider .avia-testimonial-content .avia-testimonial-meta {
	font-size: 14px !important;
}

.gform_wrapper table.gfield_list thead th {
	text-transform: capitalize !important;
	font-size: 14px !important;
	line-height: 1.3em !important;
}



input[type="radio"]+input[type="text"] {
	width: 60% !important;
	display: inline-block !important;
	margin-left: 14px !important;
}

.page-id-206 div.gform_footer.top_label {
	text-align: center;
}

.page-id-206 .gform_wrapper .gform_footer input[type=submit] {
	padding-left: 20px !important;
	background: #c3512f;
}

.page-id-206 .gform_wrapper .gform_footer input:hover[type=submit] {
	background: #883d1f;
}

.page-id-206 .main_color .input-text,
.page-id-206 .main_color input[type='text'],
.page-id-206 .main_color input[type='input'],
.page-id-206 .main_color input[type='password'],
.page-id-206 .main_color input[type='email'],
.page-id-206 .main_color input[type='number'],
.page-id-206 .main_color textarea,
.page-id-206 .main_color select {
	background: rgba(0, 0, 0, 0.4) !important;
	border-color: rgba(255, 255, 255, 0.3) !important;
	color: #ffffff !important;
}

#top .avia-button-fullwidth {
	font-size: 16px !important;
	padding: 14px !important;
}

blockquote,
blockquote p {
	font-size: 14px;
}

#footer {
	font-size: 14px;
	padding: 0;
}

@media only screen and (max-width: 767px) {

	#top #wrap_all .av_header_transparency,
	.av_header_transparency #advanced_menu_toggle {
		background: transparent !important;
		position: absolute !important;
	}
}

@media only screen and (max-width: 767px) {
	#top .av_header_transparency.av_alternate_logo_active .logo a>img {
		display: none !important;
	}

	.responsive #top .av_header_transparency .logo img.alternate {
		display: block !important;
		top: 5px !important;
	}

	.content {
		padding-top: 110px !important;
	}

	.responsive #scroll-top-link {
		display: block !important;
	}
}

.responsive #top .logo {
	top: 10px;
}

.main-title a {
	color: white !important;
	font-size: 22px !important;
}

#av-burger-menu-ul li>a:before,
.html_header_mobile_behavior #av-burger-menu-ul span>a:before,
#av-burger-menu-ul .mega_menu_title:before {
	content: "\25BA";
	position: absolute;
	top: 13px;
	margin-left: -10px;
	font-family: 'Arial';
	font-size: 7px;
}

#av-burger-menu-ul a:before {
	color: #c3512f;
}

.html_av-overlay-side-classic #top .av-burger-overlay li li .avia-bullet {
	display: none;
}

body .av-alb-blogposts,
body .av-alb-blogposts a {
	color: #355680 !important;
}

body .av-alb-blogposts .custom-format .month,
body .av-alb-blogposts .custom-format .day {
	display: block;
}

body .av-alb-blogposts .custom-format {
	padding: 10px;
	text-align: center;
}

body .av-alb-blogposts .custom-format .day {
	font-size: 32px;
	font-weight: bold;
}

body .av-alb-blogposts .custom-format .month {
	font-size: 15px;
	letter-spacing: 1px;
	text-transform: uppercase;
}

#top .custom-blog-container .av-alb-blogposts .blog-author {
	display: block;
}

#top .custom-blog-container .av-alb-blogposts .post-title {
	margin-bottom: 0;
	padding-bottom: 0;
}

body .av-alb-blogposts .custom-format {
	float: left;
	margin-right: 20px;
}

body .av-alb-blogposts .post-title {
	overflow: hidden;
}

@media only screen and (min-width: 560px) {
	#top .custom-blog-container .av-alb-blogposts .entry-content-wrapper {
		overflow: hidden;
	}
}

@media only screen and (max-width : 767px) {
	.responsive #top #wrap_all .container {
		max-width: 90% !important;
		width: 90% !important;
	}
}

// Removes "scroll to top" button
#scroll-top-link {
	display: none !important;
  }