.gform_footer input {
  float: left;
}

.iconbox_content a {
  font-size: 20px !important;
}

.image-overlay .image-overlay-inside::before {
  display: none;
}

.html_modern-blog #top .post-entry .post-title,
.html_modern-blog .avia-content-slider .slide-entry-title {
  font-size: 20px !important;
}

#av_section_3 {
  background-position: 0% 67% !important;
}

#top .avia-slider-testimonials.avia-testimonial-wrapper .avia-testimonial-row {
  top: 60px;
  @media only screen and (max-width: 560px) {
    top: 0;
  }
}

#top .av-large-testimonial-slider .avia-slideshow-arrows a {
  top: 60px;
  @media only screen and (max-width: 560px) {
    top: 0;
  }
}

.av-special-heading-tag {
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
}

.avia-testimonial_inner {
  min-height: 400px;
}

.div .av_one_fourth {
  margin-bottom: 20px;
}

.breadcrumb {
  margin-top: 5px !important;
  position: unset !important;
}

.home-events {
  .container {
    .content {
      .avia-content-slider,
      .avia-content-slider-inner {
        overflow: visible;
      }
      .av-special-heading-h2 {
        h2 {
          font: normal 30px/28px Open Sans;
          color: #4a5163;
        }
      }
      .slide-entry {
        position: relative;
        @media screen and (max-width: 767px) {
          width: 100% !important;
          margin: 20px 0 !important;
        }
        .date {
          display: none; //turning off for now
          // z-index: 101;
          // width: 69px;
          // height: 69px;
          // background-color: #33689e;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          // position: absolute;
          // top: -10px;
          // left: 10px;
          span {
            color: white;
          }
          .month {
            font: bold 16px/20px Open Sans;
            letter-spacing: 0.32px;
            text-transform: uppercase;
          }
          .day {
            font: 400 26px/28px Open Sans;
            letter-spacing: 0.52px;
          }
        }
        .slide-image {
          border-radius: 0;
          height: 16vw;
          @media only screen and(max-width : 768px) {
            height: 300px;
          }
          img {
            width: 100%;
            height: 100%;
            border-radius: 0;
            object-fit: cover;
          }
        }
        .slide-content {
          .entry-content-header {
            margin-bottom: 15px;
            .slide-entry-title {
              a {
                font: 600 22px/28px Open Sans;
              }
            }
          }
          .read-more-link {
            a {
              font: bold 18px/24px Open Sans;
              .more-link-arrow {
                &::after {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.home-presentations {
  @extend .home-events;
  .container {
    .content {
      .slide-entry {
        position: relative;
        .date {
          display: none;
        }
      }
    }
  }
}
