#av-burger-menu-ul li>a:before, .html_header_mobile_behavior #av-burger-menu-ul span>a:before, #av-burger-menu-ul .mega_menu_title:before {
    display: none;
}

// #top .header_color .av-hamburger-inner, #top .header_color .av-hamburger-inner::before, #top .header_color .av-hamburger-inner::after {
//     @media only screen and (max-width : 767px ) {
//         background-color: white !important;
//     }
// }

.av-burger-overlay-active #top #wrap_all .av-hamburger-inner, .av-burger-overlay-active #top #wrap_all .av-hamburger-inner::before, .av-burger-overlay-active #top #wrap_all .av-hamburger-inner::after, .html_av-overlay-side-classic #top div .av-burger-overlay li li .avia-bullet {
    background: #2e709c !important;
}


#top #wrap_all .av-burger-overlay .av-burger-overlay-scroll #av-burger-menu-ul .current_page_item .avia-menu-text  {
    color: #f08e21 !important;
}

#top #wrap_all .av-burger-overlay .av-burger-overlay-scroll #av-burger-menu-ul .active-parent-item .sub-menu {
    display: block;
}

// Mobile menu tweaks
@media only screen and (max-width: 767px) {
    #top {
        #wrap_all {
            #header {
                position: fixed !important;
                background-color: #0a1627 !important;
            }
        }
    }
}