#top #wrap_all {
  .avia-button.avia-color-theme-color {
    padding: 15px 35px;
    border-bottom: none;
    span {
      font: normal 18px/28px Open Sans;
      letter-spacing: 0.18px;
    }
  }
}
