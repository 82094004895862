.av_icon_caption {
  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    text-transform: capitalize;
    font-size: 12px;
  }
}

.responsive #top .container .av-content-small,
.responsive #top #wrap_all .flex_column,
.responsive #top #wrap_all .av-flex-cells .no_margin {
  @media only screen and (max-width: 768px) {
    padding: 10px !important;
  }
}
